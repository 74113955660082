import './tailwind.css';
import $ from 'jquery';
import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Application} from '../lib/application';
import {SeoText} from 'devSrc/lib/seoText';
import {EventEmitter} from 'events';
import {Currency} from 'devSrc/lib/currency';
import {Template} from 'devSrc/header/template';
import {BitrixSmallCart} from '../header/basket';
import CatalogDropdownMenu
  from '../components/catalogDropdownMenu/CatalogDropdownMenu';
import './style.css';
import './additional.css';
import 'devSrc/header/titleSearch/style.css';
import Analytics from '../lib/analytics/Analytics';
import MultisearchTracking from '../components/analytics/MultisearchTracking';
import ESputnikTracker from '../lib/esputnikTracker/index';
import {ReviewsElements} from '../reviews/reviewsElements';
import EndBanner from '../lib/endBanner/EndBanner';
import HeaderSearch from '../header/search';
import axios from 'axios';
import CryptoJS from 'crypto-js';

import 'swiper/swiper-bundle.css';
// import function to register Swiper custom elements
import {register} from 'swiper/element/bundle';
import AuthRegister from '../header/auth/AuthRegister';
// register Swiper custom elements
register();

// сквозной баннер
let end_banner_node = document.getElementById('end-to-end-banner');
if (end_banner_node) {
  (new EndBanner(end_banner_node)).init();
}

//Init global Currency
const CatalogCurrency = new Currency();

// Init template js
Template.init();

const eventEmitter = new EventEmitter();

const esputnikTracker = new ESputnikTracker();

esputnikTracker.loadScript();

// Whole site data
let someData = JSON.parse(atob(document.querySelector(
  '[data-container="template-wrapper"]').dataset['params']));
const application = new Application(someData);
let sessid = someData.sessid;
let analytics = new Analytics();
let multisearchTracking = new MultisearchTracking();

const axiosConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Bitrix-Csrf-Token': application.getSessionId(),
    'X-Lang': application.getLanguageId(),
  },
  maxRedirects: 0,
};

let lang = 'ru';

// Init catalog dropdown menu
let catalogDropdownMenuNode = document.getElementById(
  'catalog-dropdown-menu-root');
if (catalogDropdownMenuNode instanceof HTMLElement) {
  try {
    let menuData = JSON.parse(window.catalogMenuData);
    let root = createRoot(catalogDropdownMenuNode);
    root.render(<CatalogDropdownMenu {...menuData}/>);
  } catch (reason) {
    console.log(reason);
  }
}

// Init tap bar
try {
  let menuData = {};

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => (initTapBar()));
  } else {
    initTapBar();
  }

  function initTapBar() {
    try {
      menuData = JSON.parse(window.mobileMenuInitialState);
    } catch (parseError) {
      console.log(parseError);
    }

    const TapBar = lazy(
      () => import(/* webpackChunkName: "tapbar" */ '../components/tapBar/TapBar'),
    );

    //console.info('menuData', menuData);

    let root = createRoot(document.getElementById('tap-bar-root'));
    root.render(<Suspense fallback={<></>}><TapBar {...menuData}/></Suspense>);
  }

} catch (reason) {
  console.log(reason);
}

if (window.innerWidth > 1249) {
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('fixed');
    } else {
      $('.header').removeClass('fixed');
    }
  });
}
//Basket
let basketBlockNode = document.querySelector('#bx_basketlCzuLf');
let basket = null;
if (basketBlockNode instanceof HTMLElement) {
  let basketData = (basketBlockNode.dataset.params) ? JSON.parse(
    decodeURIComponent(escape(atob(basketBlockNode.dataset.params)))) : {};
  basketBlockNode.removeAttribute('data-params');
  basket = new BitrixSmallCart(basketData);
}

// событие обновления количества товара
eventEmitter.on('OnUpdateItemQuantity', data => {
  esputnikTracker.sendBasketToESputnik();
  if (data.direction !== 'up') return;
  (new Analytics()).addToCart(data.productId);
});

//Событие удаления товара из корзины
eventEmitter.on('OnRemoveItemFromCart', data => {
  esputnikTracker.sendBasketToESputnik();
});

//Search
let searchBlockNode = document.querySelector('.bx-searchtitle');
if (searchBlockNode instanceof HTMLElement) {
  let searchData = (searchBlockNode.dataset.params) ? JSON.parse(
    decodeURIComponent(escape(atob(searchBlockNode.dataset.params)))) : {};
  const titleSearch = new TitleSearch(searchData);
  titleSearch.init();
}

//Auth
let authButton = document.querySelector(
  '[data-container="user"][data-use="auth"]');
let needAuth = (authButton.dataset.needAuth === 'true');
let regButtons = [].slice.apply(
  document.querySelectorAll('a[data-container="user"][data-use="register"]'));

let authRegister;

authButton.addEventListener('click', async () => {
  if (needAuth === true) {
    const {default: React} = await import('react');
    const {createRoot} = await import('react-dom/client');
    const {default: AuthRegister} = await import('../components/authRegister/AuthRegister');

    // Создаем корневой контейнер, если его нет
    let modalRoot = document.getElementById('modal-main');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = 'modal-main';
      document.body.appendChild(modalRoot);
    }

    let dataParams = JSON.parse(atob(authButton.dataset.params));

    // Рендерим React-компонент в контейнере
    const root = createRoot(modalRoot);
    root.render(
        <AuthRegister
            getCurrentMode={() => 'auth'}
            getMessage={(key) => {
              const messages = JSON.parse(atob(authButton.dataset.messages));
              return messages[key];
            }}
            getCurrentStep={() => 'guestUser'}
            sessid={dataParams.bitrix_sessid}
            lang={dataParams.lang}
        />
    );
  }
});

//End Auth

//ReadMore
$('.read-more').on('click', function showAlloptions() {
  var closeText = $(this).data('close');
  var openText = $(this).data('open');
  $(this).siblings('.text').toggleClass('active');
  if ($(this).hasClass('open')) {
    $(this).removeClass('open');
    $(this).text(openText);
  } else {
    $(this).addClass('open');
    $(this).text(closeText);
  }
});
const seoText = new SeoText();

// Ожидаем событие load
if (window.document.readyState !== 'complete') {
  // Loading hasn't finished yet
  window.addEventListener('load', function () {
    setTimeout(loadExternal, 3000)
  });
} else {
  // `load` has already fired
  setTimeout(loadExternal, 3000)
}

// загрузка внешних ресурсов
function loadExternal() {
  // Multisearch
  (function(d) {
    var s = d.createElement('script');
    s.defer = true;
    s.src = 'https://tracker.multisearch.io/4jip6d5syb0c';
    if (d.head) d.head.appendChild(s);
  })(document);

  // Битрикс чат
  (function(w, d, u) {
    var s = d.createElement('script');
    s.async = true;
    s.src = u + '?' + (Date.now() / 60000 | 0);
    var h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  })(window, document,
    'https://cdn.bitrix24.eu/b791809/crm/site_button/loader_13_70du1k.js');

  // Stream telecom widget
  (function(w, d, u) {
    var s = d.createElement('script');
    s.async = true;
    s.src = u + '?' + (Date.now() / 60000 | 0);
    var h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  })(window, document,
    'https://widgets.streamtele.com/widget/de7ee9a9f82615b2b6893de80847fd41/');

  //Отправка в ESputnik на каждой странице
  esputnikTracker.PageView();

  // форма подписки esputnik
  if (application.getLanguageId() === 'ua') eS('showForm',
    {formVariantId: 'f8280v8280'});
  else eS('showForm', {formVariantId: 'f8627v8627'});
}

const reviewsElements = new ReviewsElements();

document.addEventListener('click', function(event) {
  if (event.target && event.target.classList.contains('catalog__faq-grid')) {
    var parent = event.target.parentElement;
    if (parent.classList.contains('active')) {
      parent.classList.remove('active');
    } else {
      var faqItems = document.querySelectorAll('.catalog__faq-item');
      faqItems.forEach(function(item) {
        item.classList.remove('active');
      });
      parent.classList.add('active');
    }
  }
});

//Search Title
let search_node = document.getElementById('bx-title-search');
let search_node_mob = document.getElementById('mobile-search');
if (search_node instanceof HTMLElement) {
  if (window.innerWidth < 1024) {
    if (search_node_mob instanceof HTMLElement) {
      search_node_mob.appendChild(search_node);
    }
  }

  let raw_params = atob(search_node.getAttribute('data-params'));
  let params = JSON.parse(raw_params);
  params.node = search_node;
  search_node.setAttribute('data-params', '');
  let root = createRoot(search_node);
  root.render(<HeaderSearch params={params}/>);
}

// Создаем новый экземпляр MutationObserver
let observer = new MutationObserver(function(mutationsList, observer) {
  // Перебираем каждое изменение в списке мутаций
  for (let mutation of mutationsList) {
    // Если добавлен новый узел
    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
      // Перебираем добавленные узлы
      mutation.addedNodes.forEach(function(addedNode) {
        // Проверяем, является ли добавленный узел элементом с атрибутом cl-connector-root-element="BUILT_IN"
        if (addedNode.nodeType === 1 &&
          addedNode.getAttribute('cl-connector-root-element') === 'BUILT_IN') {
          // Находим элемент sys-container-static внутри родительского элемента
          let containerStaticElement = addedNode.querySelector(
            'sys-container-static[cl-type="VIEW"]');
          // Получаем shadow root элемента containerStaticElement
          let shadowRoot = containerStaticElement.shadowRoot;
          // Внутри этого div есть второй sys-container-static с cl-type="CONTAINER"
          let containerStatics = shadowRoot.querySelectorAll(
            'sys-container-static[cl-type="CONTAINER"]');
          let secondContainerStatic = containerStatics[1];
          let shadowRootTwo = secondContainerStatic.shadowRoot;
          let containerInputs = shadowRootTwo.querySelectorAll(
            'sys-input-static[cl-type="INPUT"]');
          let firstContainerInput = containerInputs[0];
          let shadowRooTree = firstContainerInput.shadowRoot;
          let containerInput = shadowRooTree.querySelector(
            'input[cl-element="input"]');

          // Добавляем обработчик события change на input элемент
          containerInput.addEventListener('change', function(event) {
            // Получаем значение, введенное пользователем
            let enteredValue = event.target.value;

            // Регулярное выражение для проверки адреса электронной почты
            let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Проверяем, соответствует ли введенное значение регулярному выражению для адреса электронной почты
            if (emailRegex.test(enteredValue)) {
              const updateLocalStorage = (key, newValue) => {
                // Хешируем новое значение
                const hashedNewValue = CryptoJS.SHA256(newValue).toString();

                // Проверяем существующее значение в localStorage
                const existingHashedValue = localStorage.getItem(key);

                // Если значения отличаются, обновляем localStorage
                if (existingHashedValue !== hashedNewValue) {
                  localStorage.setItem(key, hashedNewValue);
                }
              };

              // Обновляем или создаем записи в localStorage
              updateLocalStorage('user_data.sha256_email_address', enteredValue);
            }
          });
        }
      });
    }
  }
});

// Начинаем отслеживать изменения в DOM
observer.observe(document.body, {childList: true, subtree: true});

// Получаем элемент wishlistItem из localStorage
let wishlistItem = localStorage.getItem('wishlistItem');
// Если wishlistItem пустой или не существует

if (wishlistItem === null && needAuth === false) {
  const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

  let formData = new FormData();
  formData.append('sessid', application.getSessionId());
  formData.append('productList', 'Y');

  axios.post('/api/wishlist/wishlistAction/', formData, config).
    then(response => {
      localStorage.setItem('wishlistItem', JSON.stringify(response.data.list));
    }).
    catch(e => {
      console.log(e);
    });
}

//Если пользователь не авторизован, и у него не очищен список желаний - очищаем
if (wishlistItem !== null && needAuth === true) {
  localStorage.removeItem('wishlistItem');
}

(new AuthRegister({ eventEmitter: eventEmitter })).initLoader()

export {
  eventEmitter,
  application,
  CatalogCurrency,
  basket,
  authRegister,
  esputnikTracker,
  analytics,
  lang,
  sessid,
  axiosConfig,
  multisearchTracking,
};